<script setup lang="ts">
import { useImage, type ImageType } from "@/composables/useImage";
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { BreakPointsKey } from "@/providerKeys";
import { faFileCircleExclamation } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref, watch } from "vue";
import SHSpinner from "./SHSpinner.vue";

const {
  alt = "unknown",
  type = "default",
  proxyRequest = true,
  responsive = false,
  src = "",
  ...props
} = defineProps<{
  alt?: string;
  proxyRequest?: boolean;
  src: string;
  responsive?: boolean;
  type?: ImageType;
  isSsr?: boolean;
}>();

const { log } = useLogger("SHImage");
const breakpoints = injectStrict(BreakPointsKey);
const imgproxy = useImage();
const isLoading = ref(false);
const loadFailed = ref(false);
const srcImage = computed(() => src);
const isSsr = computed(() => props.isSsr || import.meta.env.SSR);

defineExpose({
  loadFailed,
  isLoading
});

watch(
  srcImage,
  () => {
    loadFailed.value = false;
    isLoading.value = true;
  },
  { immediate: true }
);

const onError = (src: string) => {
  loadFailed.value = true;
  isLoading.value = false;
  log("Unable to load image: ", src);
};

const onLoaded = () => {
  isLoading.value = false;
  loadFailed.value = false;
};
</script>

<template>
  <div :class="{ shImage: !loadFailed }">
    <div v-if="loadFailed" class="missing-image vertical">
      <FontAwesomeIcon
        :icon="faFileCircleExclamation"
        :size="breakpoints.includes('desktop') ? '3x' : '1x'"
      />
      <span v-if="breakpoints.includes('desktop')" class="alt-text">
        ({{ alt }})
      </span>
    </div>

    <template v-else>
      <div v-if="!isSsr && isLoading" class="spinner vertical">
        <SHSpinner />
        <span class="alt-text">{{ alt }}</span>
      </div>

      <img
        v-show="isSsr || !isLoading"
        v-bind="$attrs"
        :class="[type]"
        :src="proxyRequest ? imgproxy[type](srcImage) : srcImage"
        :srcset="
          responsive && proxyRequest ? imgproxy[type](srcImage, responsive) : ''
        "
        :alt="alt"
        @error="onError(src)"
        @load="onLoaded"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.shImage {
  user-select: none;

  .alt-text {
    font-size: 0.75em;
  }
}

.missing-image {
  color: var(--color-danger);
  min-width: 2em;
}

.missing-image,
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<script async setup lang="ts">
import ReviewEventCard from "@/components/ActivityView/ReviewEventCard.vue";
import AppLink from "@/components/AppLink.vue";
import SHAgentChooser from "@/components/SHAgentChooser.vue";
import SHButton from "@/components/SHButton.vue";
import SHField from "@/components/SHField.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import TimeLine from "@/components/TimeLine.vue";
import TimeLineEntry from "@/components/TimeLineEntry.vue";
import UserLink from "@/components/UserLink.vue";
import type { AgentChoice } from "@/composables/useAgentChoice";
import { useTicketReview } from "@/composables/useTicketReview";
import { useTipTap } from "@/composables/useTipTap";
import { useToaster } from "@/composables/useToaster";
import { graphql, useFragment } from "@/generated";
import {
  Event_Types_Enum,
  ReviewEventCardFragmentDoc,
  Ticket_Review_Event_Types_Enum
} from "@/generated/graphql";
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { CurrentUserKey } from "@/providerKeys";
import { faArrowDownFromLine } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { JSONContent } from "@tiptap/vue-3";
import { useQuery, useSubscription } from "@urql/vue";
import { v4 } from "uuid";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const { log } = useLogger("TicketReview"); // eslint-disable-line @typescript-eslint/no-unused-vars

const { ticketId } = defineProps<{
  ticketId: string;
}>();

const router = useRouter();
const currentUser = injectStrict(CurrentUserKey);
const { createToast } = useToaster();

const fragment = graphql(/* GraphQL */ `
  fragment TicketReview on tickets {
    id
    created_at
    author {
      ...UserLink
    }
    ...TicketReview_Ticket
  }
`);
const { data, fetching, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query GetTicketReviewData($ticketId: uuid!) {
      tickets_by_pk(id: $ticketId) {
        ...TicketReview
      }
    }
  `),
  variables: computed(() => ({
    ticketId
  }))
});
const { data: subscriptionData } = useSubscription({
  query: graphql(/* GraphQL */ `
    subscription GetTicketReviewDataSubscription($ticketId: uuid!) {
      tickets_by_pk(id: $ticketId) {
        ...TicketReview
      }
    }
  `),
  variables: computed(() => ({
    ticketId
  }))
});

const ticket = computed(() =>
  useFragment(
    fragment,
    subscriptionData.value?.tickets_by_pk ?? data.value?.tickets_by_pk
  )
);

const notes = ref<JSONContent | null>(null);
const requestingReviewer = ref<AgentChoice | null>(null);

const {
  reviewEvents,
  eventOptions,
  updateReview,
  currentReviewerId,
  bindingsWithCacheValue,
  allRequiredFormsFilledOut,
  isMissingSignature,
  canRequest
} = useTicketReview(ticket);

const editor = useTipTap(notes);

async function onSubmit(reviewEvent: Ticket_Review_Event_Types_Enum) {
  let notificationEventType: Event_Types_Enum;
  let desiredReviewerId: string | null | undefined;
  let toastMessage = "";
  let toastTitle = "";
  let toastErrorMessage = "";

  switch (reviewEvent) {
    case Ticket_Review_Event_Types_Enum.Request:
      toastMessage = "Review requested";
      toastTitle = "Review requested";
      toastErrorMessage = "Review not requested";
      notificationEventType = Event_Types_Enum.TicketReviewRequested;
      desiredReviewerId = requestingReviewer.value?.id;
      break;
    case Ticket_Review_Event_Types_Enum.Cancel:
      toastMessage = "Review cancelled";
      toastTitle = "Review cancelled";
      toastErrorMessage = "Review not cancelled";
      notificationEventType = Event_Types_Enum.TicketReviewCancelled;
      desiredReviewerId = currentReviewerId.value;
      break;
    case Ticket_Review_Event_Types_Enum.Accept:
      toastMessage = "Review accepted";
      toastTitle = "Review accepted";
      toastErrorMessage = "Review not accepted";
      notificationEventType = Event_Types_Enum.TicketReviewAccepted;
      desiredReviewerId = currentReviewerId.value;
      break;
    case Ticket_Review_Event_Types_Enum.Reject:
      toastMessage = "Review rejected";
      toastTitle = "Review rejected";
      toastErrorMessage = "Review not rejected";
      notificationEventType = Event_Types_Enum.TicketReviewRejected;
      desiredReviewerId = currentReviewerId.value;
      break;
    case Ticket_Review_Event_Types_Enum.Comment:
      toastMessage = "Comment added";
      toastTitle = "Comment added";
      toastErrorMessage = "Comment not added";
      notificationEventType = Event_Types_Enum.TicketReviewCommented;
      desiredReviewerId = currentReviewerId.value;
      break;
    default:
      throw new Error("Unknown notification event type");
  }

  const eventId = v4();
  const { data, error } = await updateReview({
    reviewEvent: {
      id: eventId,
      ticket_id: ticketId,
      ticket_review_event_type: reviewEvent,
      desired_reviewer_id: desiredReviewerId,
      notesj: notes.value
    },
    eventNotification: {
      event_id: v4(),
      payload: {
        type: notificationEventType,
        version: 1,
        data: {
          author_id: currentUser.value.id,
          ticket_review_event_id: eventId,
          ticket_id: ticketId
        }
      }
    }
  });

  if (error) {
    createToast({
      theme: "danger",
      title: toastErrorMessage,
      message: error.message || "Unkown error."
    });
  }

  if (data) {
    createToast({
      theme: "success",
      title: toastTitle,
      message: toastMessage
    });
  }

  notes.value = null;

  if (reviewEvent === Ticket_Review_Event_Types_Enum.Accept) {
    router.back();
  }
}
</script>

<template>
  <article class="vertical loose">
    <SHSpinner v-if="fetching" />
    <SHNote v-else-if="error" theme="danger">{{ error }}</SHNote>
    <SHNote v-else-if="!ticket" theme="danger">404</SHNote>
    <template v-else>
      <SHNote
        v-if="!allRequiredFormsFilledOut"
        class="required-forms-warning"
        theme="warning"
      >
        Not all required forms are filled out.
      </SHNote>
      <SHNote v-else-if="isMissingSignature" theme="warning">
        Signature is required for this customer.
      </SHNote>
      <SHNote v-else-if="bindingsWithCacheValue.length" theme="warning">
        <p>You have unsaved changes in one or more forms:</p>
        <ul>
          <li
            v-for="binding in bindingsWithCacheValue"
            :key="binding.definition.component_name"
          >
            <AppLink
              :to="{
                name: 'CustomFormDetail',
                query: {
                  ticketCustomFormEntryId: binding.serverValue?.id,
                  customFormDefinitionId: binding.definition.id
                }
              }"
            >
              {{ binding.definition.title }}
            </AppLink>
          </li>
        </ul>
      </SHNote>
      <SHNote v-else-if="canRequest" theme="warning">
        By requesting review of this ticket, you are signaling the work is
        finished. Once requested, you can no longer edit work logs, travel logs,
        expenses, or forms. You may cancel the request to be able to edit.
      </SHNote>
      <TimeLine>
        <!-- Manually placed timeline "event" for when this ticket was created -->
        <TimeLineEntry
          icon-fg-color="var(--color-surface-400)"
          icon-bg-color="var(--color-surface-100)"
          :start="ticket?.created_at"
        >
          <template #icon>
            <FontAwesomeIcon :icon="faArrowDownFromLine" fixed-width />
          </template>

          <UserLink :user="ticket?.author" link />
          created ticket.
        </TimeLineEntry>

        <TransitionGroup name="list">
          <ReviewEventCard
            v-for="r in reviewEvents"
            :key="useFragment(ReviewEventCardFragmentDoc, r)?.id"
            :reviewEvent="r"
          />
        </TransitionGroup>
      </TimeLine>
    </template>

    <footer class="vertical">
      <article class="review-event-form vertical">
        <SHField v-if="false && canRequest" label="Select reviewer">
          <SHAgentChooser
            placeholder="All supervisors"
            :model-value="requestingReviewer"
            :clearable="false"
            @update:model-value="requestingReviewer = $event"
          />
        </SHField>

        <div class="vertical">
          <SHField label="Notes" block>
            <SHTextEditor
              v-model="notes"
              style="flex: 1"
              :rows="5"
              :editor="editor"
              editable
            />
          </SHField>
          <div class="level-end tight">
            <template v-for="option in eventOptions" :key="option.label">
              <SHButton
                v-if="option.show"
                :color="option.color"
                :disabled="
                  (option.value === Ticket_Review_Event_Types_Enum.Reject &&
                    editor?.isEmpty) ||
                  (option.value === Ticket_Review_Event_Types_Enum.Comment &&
                    editor?.isEmpty)
                "
                @click="onSubmit(option.value)"
              >
                {{ option.label }}
              </SHButton>
            </template>
          </div>
        </div>
      </article>
    </footer>
  </article>
</template>
<style lang="scss" scoped>
.required-forms-warning {
  width: 100%;
}
</style>
